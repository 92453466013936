@keyframes blinker {
    50% {
        opacity: 0.4;
    }
}

@keyframes fadeOut {
    100% {
        opacity: 0;
    }
}